<template>
  <div class="wet-accordeon">
    <header
      ref="header"
      class="wet-accordeon-header"
      :class="{
        'active': open,
      }"
      @click="toggle"
    >
      <slot name="header"></slot>
    </header>
    <section ref="content" class="wet-accordeon-content" v-collapse="open">
      <slot name="content"></slot>
    </section>
  </div>
</template>
<script>
export default {
  props: {
    open: Boolean,
  },
  methods: {
    toggle() {
      this.$emit('update:open', !this.open);
    },
  },
};
</script>
<style lang="scss">
  .wet-accordeon-header {
    cursor: pointer;
    transition: color .3s;
    font-weight: bold;

    &.active {
      color: var(--secondary);
    }
  }
  .wet-accordeon-content {
    box-shadow:
      inset 0 1.5rem .75rem -.75rem rgba(#dddddd, .3),
      inset 0 -1.5rem .75rem -.75rem rgba(#dddddd, .3);
  }
</style>
